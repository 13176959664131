/* You can add global styles to this file, and also import other style files */

// Main style
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Demos */
@import "./metronic/css/demos/demo1.css";

// Keenicons
@import "./metronic/vendors/keenicons/duotone/style.css";
@import "./metronic/vendors/keenicons/filled/style.css";
@import "./metronic/vendors/keenicons/outline/style.css";
@import "./metronic/vendors/keenicons/solid/style.css";

:root {
    --bs-border-color: var(--tw-gray-500);
    --bs-tertiary-bg: var(--tw-gray-400);
    --bs-info: var(--tw-info-600);
}

.ngb-dp-content {
    background: var(--tw-gray-100);
}

// overwrites
.table tbody tr td, .table tbody tr th {
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.table thead td, .table thead th, .table tfoot td, .table tfoot th {
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}

.hero-bg {
    background-image: url(assets/media/images/2600x1200/bg-1.png);
}

.upgrade-bg {
    background-image: url(assets/media/images/2600x1200/bg-5.png);
}
    